import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/docs-layout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "niceview"
    }}>{`nice!view`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAGoWsbiF//EABgQAAMBAQAAAAAAAAAAAAAAAAABEQID/9oACAEBAAEFAnuJdVSGUin/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAACAwAAAAAAAAAAAAAAAAAQMQABEf/aAAgBAQAGPwKLCqH/xAAZEAEBAAMBAAAAAAAAAAAAAAABEQAQMbH/2gAIAQEAAT8hCkXGAaLzUJEuDnlp/9oADAMBAAIAAwAAABBs7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAQUAAAAAAAAAAAAAAAEAETEhQVFxof/aAAgBAQABPxA4GLQR1wgJt13xAaiMIcJcuRYYaTp7P//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "nice!view",
          "title": "nice!view",
          "src": "/static/6a3b123088ccdaf4e3022619d64ccf6b/6aca1/niceview.jpg",
          "srcSet": ["/static/6a3b123088ccdaf4e3022619d64ccf6b/d2f63/niceview.jpg 163w", "/static/6a3b123088ccdaf4e3022619d64ccf6b/c989d/niceview.jpg 325w", "/static/6a3b123088ccdaf4e3022619d64ccf6b/6aca1/niceview.jpg 650w", "/static/6a3b123088ccdaf4e3022619d64ccf6b/7c09c/niceview.jpg 975w", "/static/6a3b123088ccdaf4e3022619d64ccf6b/01ab0/niceview.jpg 1300w", "/static/6a3b123088ccdaf4e3022619d64ccf6b/d1ad8/niceview.jpg 1621w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`The nice!view is a SSD1306 OLED replacement boasting >1,000x power savings while keeping a 30Hz refresh rate. It has a similar pinout to SSD1306 OLEDs with one extra pin making it easy to add on to existing boards. `}</p>
    <p>{`To get your nice!view up and running check out `}<a parentName="p" {...{
        "href": "/docs/nice-view/getting-started"
      }}><strong parentName="a">{`Getting Started`}</strong></a>{`.`}</p>
    <p>{`If you're a hardware designer interested to learn more about the pinout and schematic check out the `}<a parentName="p" {...{
        "href": "/docs/nice-view/pinout-schematic"
      }}><strong parentName="a">{`Pinout and Schematic`}</strong></a>{`.`}</p>
    <h2 {...{
      "id": "specifications"
    }}>{`Specifications`}</h2>
    <ul>
      <li parentName="ul">{`160x68 pixel resolution with a diagonal size of 1.08"`}</li>
      <li parentName="ul">{`36x14x2.9mm dimensions`}</li>
      <li parentName="ul">{`30Hz refresh rate`}</li>
      <li parentName="ul">{`<10uA typical power draw`}</li>
      <li parentName="ul">{`Conformal coat to reduce the possibility of shorts`}</li>
      <li parentName="ul">{`Socket and pins included`}</li>
      <li parentName="ul">{`3-wire SPI protocol`}</li>
      <li parentName="ul">{`3.3V voltage`}</li>
      <li parentName="ul">{`Sharp LS011B7DH03 display`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      